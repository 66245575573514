import React from "react"
export default function Footer() {
  return (
    <section className="section footer has-background-secondary">
      <div className="footer-inner" style={{display: "flex", alignItems: "center", height: "100%"}}>
        <div className="columns">
          <div className="column is-one-fifth">
            <img className="footer-logo" src="assets/prairie-process-serving-logo-white.png" alt="white logo" />
          </div>
          <div className="column is-one-quarter">
            <p className="footer-column-title">Hours of Operation</p>
            <p>8:00 a.m. to 4:30 p.m.<br/>Monday to Friday</p>          
            <p>For emergencies after the hours noted above please call <a className="transition" href="tel:+3062609861">306.260.9861</a></p>
            <p>We accept all modes of payment.</p>
          </div>
          <div className="column is-one-quarter">
            <p className="footer-column-title">Mailing Address</p>
            <p>Prairie Process Serving</p>
            <p>PO Box 233<br/>
              Asquith<br/>
              SK S0K 0J0<br/>
            </p>
            <p>Should you need to courier documents to Saskatoon please call our office and we will provide you with an alternative address.</p>
          </div>
          <div className="column">
            <p className="footer-column-title">Contact</p>
            <p><a className="transition" href="tel:+3062609861">306.260.9861</a></p>
            <p><a className="transition" href="tel:+3063290009">Office: 306.329.0009</a></p>
            <p className="footer-email"><a className="transition" style={{"overflow-wrap":"anywhere"}} href="mailto:service@prairieprocessserving.com">service@prairieprocessserving.com</a></p>
            <p className="has-text-white"><a className="fb transition" target="_blank" href="https://www.linkedin.com/company/prairie-process-serving-ltd/"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" style={{"width":"20px"}}><path class="b" d="m3.64,0C11.21,0,18.79,0,26.37,0c.06.02.11.04.17.05,1.7.32,2.8,1.31,3.32,2.96.06.2.1.41.15.62v22.73c-.02.06-.04.13-.05.19-.34,2-2.01,3.43-4.03,3.44-7.28,0-14.56,0-21.85,0C1.82,29.99,0,28.18,0,25.93,0,18.64,0,11.35.01,4.06.02,2.42.81,1.2,2.26.45,2.68.23,3.18.15,3.64,0Zm12.35,13.31c0-.32,0-.57,0-.82-.01-.45-.22-.67-.66-.67-.88,0-1.76,0-2.64,0-.45,0-.67.21-.67.66,0,3.74,0,7.48,0,11.22,0,.46.22.67.69.68.81,0,1.62,0,2.43,0,.66,0,.83-.17.83-.83,0-1.87,0-3.75,0-5.62,0-.71.15-1.38.53-1.98.48-.76,1.19-1,2.04-.89.78.1,1.3.56,1.48,1.31.15.61.21,1.25.22,1.88.03,1.81,0,3.61,0,5.42,0,.51.2.71.71.71.88,0,1.76,0,2.64,0,.5,0,.71-.21.71-.72,0-2.07.02-4.14-.02-6.21-.02-.84-.11-1.7-.29-2.52-.34-1.54-1.19-2.7-2.79-3.1-.61-.15-1.25-.19-1.88-.18-1.02,0-1.97.27-2.74,1-.2.19-.38.41-.61.67Zm-9.71,4.76c0,1.85,0,3.71,0,5.56,0,.5.24.73.74.73.82,0,1.64,0,2.46,0,.52,0,.76-.23.76-.75,0-3.69,0-7.38,0-11.07,0-.52-.23-.75-.76-.75-.81,0-1.62,0-2.43,0-.56,0-.77.23-.77.79,0,1.83,0,3.65,0,5.48Zm4.53-9.87c.02-1.39-1.11-2.55-2.49-2.58-1.43-.02-2.6,1.11-2.61,2.52-.02,1.41,1.11,2.57,2.52,2.59,1.44.01,2.58-1.1,2.59-2.53Z"/></svg></a></p>
          </div>
        </div>
      </div>
    </section>
  )
}